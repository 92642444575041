/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Image, ContactForm, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"KONTAKT"}>
        <Column className="--menu pb--08 pt--08" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--22" content={"<a href=\"https://michaelasynacovafotografie.cz\">Michaela Synáčová Fotografie </a>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

              </MenuWrapBox>

              <MenuWrapBox style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz"} use={"url"} href={"https://michaelasynacovafotografie.cz"} target={""} content={"<span style=\"font-weight: 400; color: rgb(0, 0, 0);\">home</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/foceni"} target={""} content={"<span style=\"caret-color: rgb(2, 0, 0); color: rgb(2, 0, 0); font-weight: 400;\">CENÍKY</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/info/"} use={"url"} href={"https://michaelasynacovafotografie.cz/info/"} target={""} content={"<span style=\"font-weight: 400; color: rgb(4, 0, 0);\">INFO</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/kontakt/"} use={"url"} href={"https://michaelasynacovafotografie.cz/kontakt/"} target={""} content={"<div><span style=\"font-weight: normal; color: rgb(0, 0, 0);\">KONTAKT</span><br></div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--60 pt--60" name={"w7rxnj632o"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"KONTAKT"}>
              </Subtitle>

              <Title className="title-box" content={"Michaela Synáčová Fotografie"}>
              </Title>

              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/13101/f96e8a73aabb4dfba8cb04b4efb7c76b_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13101/f96e8a73aabb4dfba8cb04b4efb7c76b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13101/f96e8a73aabb4dfba8cb04b4efb7c76b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13101/f96e8a73aabb4dfba8cb04b4efb7c76b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13101/f96e8a73aabb4dfba8cb04b4efb7c76b_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":60}} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/6b72aab19c4f4d1ca38a5ca397b2ec1c.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box fs--24" content={"michaela.synacova.fotografie<br>@gmail.com"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":60}} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/965c3e40d27d46d385ed085e1b11d2fa.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"+420 739 449 891<br><br>IČO&nbsp;08517428<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":60}} src={"https://cdn.swbpg.com/o/g/Ikonky/Adresa/adresa-10.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"5.května 653, Blovice"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box" href={"/atelier"} content={"ATELIÉR"}>
              </Button>

              <Title className="title-box" content={"<br>"}>
              </Title>

              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--20 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Michaela Synáčová Fotografie<br></span><br>"}>
              </Subtitle>

              <Title className="title-box fs--26 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">NEWBORN A RODINNÁ FOTOGRAFKA</span>"}>
              </Title>

              <Text className="text-box fs--14 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">5.května 653, Blovice</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">+420 739 449 891</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert mt--0" content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">michaela.synacova.fotografie@gmail.com</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn2 fs--16" href={"https://www.facebook.com/michaelasynacova.fotografie"} content={"<span style=\"color: rgb(0, 0, 0);\">facebook</span>"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 fs--16" href={"https://www.instagram.com/michaela.synacova.fotografie/"} content={"<span style=\"color: rgb(0, 0, 0);\">instagram</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}